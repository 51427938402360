<template>
  <div class="text-right">
    <b-table :data="filas">
      <b-table-column class="pl-0 text-sm" v-slot="props" label="Concepto">
        <span class="font-bold">{{ props.row.nombre }}</span>
      </b-table-column>
      <b-table-column class="text-right text-sm" v-slot="props" label="Cantidad">
        <div v-if="typeof props.row.cantidad === 'number'">
          {{ props.row.cantidad | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" v-slot="props" label="IVA">
        <div v-if="typeof props.row.iva === 'number'">
          {{ props.row.iva | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
    </b-table>
    <b-button size="is-small" type="is-text" @click="is_modal_open = true"
      >Ver detalle completo</b-button
    >
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_open"
        :active.sync="is_modal_open"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-detalle-saldos :saldos="desglose" />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import ModalDetalleSaldos from "@/components/modals/ModalDetalleSaldos";

export default {
  name: "SaldoTableCuentas",
  props: {
    desglose: Object,
    nombreCuenta: String,
  },
  components: {
    ModalDetalleSaldos
  },
  data() {
    return {
      is_modal_open: false
    };
  },
  computed: {
    columns() {
      let col = [
        { label: "Concepto", headerClass: "pl-0 text-sm", field: "nombre" },
        { label: "Cantidad", headerClass: "text-right text-sm", field: "cantidad" },
        { label: "IVA", headerClass: "text-right text-sm", field: "iva" }
      ];
      return col;
    },
    filas() {
      return [
        {
          nombre: this.nombreCuenta ?? "Cuenta cobrar pagar",
          cantidad: this.desglose.cuentas_cobrar_pagar,
          iva: this.desglose.iva_cuentas_cobrar_pagar
        }
      ];
    }
  }
};
</script>
