<template>
  <Layout class="main-cols">
    <div class="container is-fluid md:ml-0">
      <div class="flex items-center">
        <b-icon
          icon="arrow-left"
          class="has-text-primary has-background-primary-02 p-5 rounded-full ml-1 cursor-pointer"
          @click.native="$router.back()"
        />
        <p class="text-2xl font-bold ml-4">Pago #{{ $route.params.idPago }}</p>
        <!-- <b-button
                v-if="getPago.cancelable"
                class="bg-transparent text-red-500 border-0"
                @click="eliminarPago"
                icon-right="trash" /> -->
      </div>
      <tabs
        :tabs="tabs"
        v-model="activeTab"
        size="is-medium"
        type="default"
        :expanded="false"
      />
      <informacion :pago="getPago" v-if="activeTab == 0 && getPago != null" />
      <comentarios
        class="pt-2"
        v-if="activeTab == 1 && getComentarios != null"
        :comentarios="getComentarios"
        :key="reload"
        @comentar="enviarComentario"
        tipo="pago"
      />
      <documentos
        v-if="activeTab == 2 && getDocumentos != null"
        :documentos="getDocumentos"
        class="mt-4"
        @desligar="desligarDocumento"
        tipo="pago"
        @togglecf="toggleCapvitalFiles"
      />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Comentarios from "@/components/generals/Comentarios";
import Documentos from "@/components/generals/Documentos";
import Informacion from "@/components/pago/Informacion";
import Tabs from "@/components/generals/Tabs";

export default {
  name: "DetallePago",
  computed: {
    ...mapGetters("pagos", ["getPago", "getComentarios", "getDocumentos"]),
    ...mapGetters("catalogos", ["getEjecutivos"]),
    tabs() {
      return [
        {
          label: "Información"
        },
        {
          label: "Comentarios"
        },
        {
          label: "Documentos"
        }
      ];
    }
  },
  components: {
    Comentarios,
    Documentos,
    Tabs,
    Informacion
  },
  data() {
    return {
      reload: 0,
      activeTab: 0
    };
  },
  watch: {
    $route: {
      handler: function() {
        if (
          (this.getPago && this.getPago.id !== this.$route.params.idPago) ||
          !this.getPago
        ) {
          this.$store.dispatch("pagos/resetState");

          this.$store.dispatch("pagos/getPago", this.$route.params.idPago);
          if (this.getComentarios == null) {
            this.$store.dispatch("pagos/getComentarios", this.$route.params.idPago);
          }
          if (this.getDocumentos == null) {
            this.$store.dispatch("pagos/getDocumentos", this.$route.params.idPago);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /*
      Se agrega comentario por API al pago
      @data(Obj): Objeto del comentario
    */
    enviarComentario(data) {
      data.texto = data.comentario;
      data.id = this.$route.params.idPago;
      this.$store.dispatch("pagos/agregarComentario", data).then(() => {
        this.reload += 1;
        setTimeout(() => {
          document
            .querySelectorAll("span.w-full > div")
            [
              document.querySelectorAll("span.w-full > div").length - 1
            ].scrollIntoView({ behavior: "smooth" });
        }, 100);
      });
    },
    /*
    Se desliga documento del pago
    @id(Int) id del archivo a desligar
    */
    desligarDocumento(id) {
      let data = {
        file_id: id,
        id: this.$route.params.idPago
      };
      this.$store.dispatch("pagos/desligarArchivos", data);
    },
    // Activa Capvital Files con la información necesaria del objeto a agregar documentos
    toggleCapvitalFiles() {
      let data = {
        type: "pago",
        id: this.$route.params.idPago,
        persona: this.getPago.lineaCredito
          ? this.getPago.lineaCredito.acreditado
          : this.getPago.acreditado,
        status: true
      };
      this.$store.dispatch("files/toggleCapvitalFiles", data);
    },
    eliminarPago(){
            this.$store.dispatch("disposicion/deletePago", this.$route.params.idPago)
                .then(res => {
                    this.notification(
                        "success",
                        "Operación realizada con exito",
                        `${res.message}`
                    );
                    this.$router.push({
                      name: "disposicion",
                      params: {
                        idDisposicion: this.getPago.creditos_core_id
                      }
                    });
                })
                .catch(error => {
                    this.notification(
                        "warning",
                        "No se pudó eliminar el pago",
                        `${error.message}`
                    );
                });
        }
  },
  mounted() {
    this.$store.dispatch("pagos/getPago", this.$route.params.idPago);
    if (this.getComentarios == null) {
      this.$store.dispatch("pagos/getComentarios", this.$route.params.idPago);
    }
    if (this.getDocumentos == null) {
      this.$store.dispatch("pagos/getDocumentos", this.$route.params.idPago);
    }
  }
};
</script>
